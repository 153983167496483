import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import NavigationBar from './components/NavigationBar';

export const ATSSRoutes = {
  default: '/',
  dashboard: '/dashboard',
  login: '/login',
  manageRoster: '/manage-roster',
  permit: '/permit',
}

const router = createBrowserRouter([
  // {
  //   path: "*",
  //   async lazy() {
  //     let maintenance = await import("./containers/maintenance");
  //     return { Component: maintenance.default };
  //   },
  // },
  {
    path: ATSSRoutes.default,
    element: <NavigationBar />,
    children: [
      {
        path: ATSSRoutes.default,
        async lazy() {
          let dashboard = await import("./containers/dashboard");
          return { Component: dashboard.default };
        },
      },
      {
        path: ATSSRoutes.dashboard,
        async lazy() {
          let dashboard = await import("./containers/dashboard");
          return { Component: dashboard.default };
        },
      },
      {
        path: ATSSRoutes.manageRoster,
        async lazy() {
          let login = await import("./containers/manage_roster");
          return { Component: login.default };
        },
      },
      {
        path: ATSSRoutes.permit,
        async lazy() {
          let login = await import("./containers/permit");
          return { Component: login.default };
        },
      },
    ]
  },
  {
    path: ATSSRoutes.login,
    async lazy() {
      let login = await import("./containers/login");
      return { Component: login.default };
    },
  },
]);

const theme = createTheme({
  palette: {
    error: {
      main: '#ff0000',
      dark: '#da0000',
      light: '#ff5436'
    }
  }
}, {
  custom: {
    cardBackground: '#EDEDED',
    labelColor: '#7f7f7f',
    iconColor: '#595959',
    arrowColor: '#F5F7FC',
    borderColor: '#D5D5D5'
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  )
}

export default App;
