import { axiosATSSInstance } from "../../../utils/axios_config"

const VehicleServiceURL = {
    vehicle: 'vehicle',
    vehiclePriority: 'vehicle/priority',
    vehicleAvailability: 'vehicle/availability',
    rtv: 'vehicle/real-time-viewer',
}

export class VehicleService {
    static getInstance() {
        return new VehicleService();
    }

    async getPriorityVehicles(zoneID, signal) {
        return axiosATSSInstance.get(`${VehicleServiceURL.vehiclePriority}?origin_id=${zoneID}&permits=true`, { signal: signal });
    }

    async getVehiclesAvailability(zoneID, signal) {
        return axiosATSSInstance.get(`${VehicleServiceURL.vehicleAvailability}/${zoneID}`, { signal: signal });
    }

    async getRTV(zoneID, signal) {
        return axiosATSSInstance.get(`${VehicleServiceURL.rtv}/${zoneID}`, { signal: signal });
    }

    async putPriorityVehicles(roster_group_id, data, signal){
        return axiosATSSInstance.put(`${VehicleServiceURL.vehiclePriority}/${roster_group_id}`, data, { signal: signal });
    }

    async putVehicleBreakdown(id, payload, signal){
        return axiosATSSInstance.put(`${VehicleServiceURL.vehicle}/${id}/breakdown`, payload, { signal: signal });
    }

    async putVehicleOnBreak(id, payload, signal){
        return axiosATSSInstance.put(`${VehicleServiceURL.vehicle}/${id}/on-break`, payload, { signal: signal });
    }


    errorHandler(error) {
        const config = error.config;
        const response = error.response;
        if (response && response.status === 400) {
            switch (config.url) {
                case VehicleServiceURL.vehiclePriority:
                    return {
                        title: response.data,
                        message: ''
                    }
                default:
                    return {
                        title: 'Invalid endpoint',
                        message: 'Please check the url'
                    }
            }
        }
        else if (response && response.status === 500) {
            return {
                title: 'Internal Server Error',
                message: ''
            }
        }
        
        return {
            title: `Unknown Error (${response.status})`,
            message: ''
        }
    }
}